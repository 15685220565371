
import {
  defineComponent,
  reactive,
  onMounted,
  toRefs,
  onBeforeUnmount,
  watch,
  ref,
  computed,
  nextTick,
  onUnmounted
} from 'vue'
import { tHeaderList, tableData, listHK, listUS, listA, listTW } from './data'
import { ElMessage, ElMessageBox } from 'element-plus'
import up from '@/assets/images/home/up.svg'
import dropdown from './component/dropdown.vue'
import dropdownInput from './component/dropdownInput.vue'
import userInfo from './component/userInfo.vue'
// import unlock from './component/unlock.vue'
import confirmPopup from './component/confirmPopup.vue'
import tipcom from './component/tipcom.vue'
import _ from 'lodash'
import prompt from './component/prompt.vue'
import down from '@/assets/images/home/down.svg'
import info from '@/assets/images/home/info.png'
import infop from '@/assets/images/home/infop.png'
import info1 from '@/assets/images/home/info1.png'
import ok1 from '@/assets/images/home/ok1.png'
import ok2 from '@/assets/images/home/ok.png'
import diamond from '@/assets/images/home/diamond.svg'
import close from '@/assets/images/home/close.png'
import { useRoute } from 'vue-router'
// 精度库
import { Decimal } from 'decimal.js'
import { useQuery, useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { STOCKQUOTE, COMMON } from '@/define'
import {
  transformQuoteField,
  transformSocketFieldUsAfterOrBefore
} from '@/utils/formatStock'
import { numFormat, numFormatK } from '@/utils/num_format'
import storage, { StorageType } from '@/utils/storage'
import { useStore } from '@/store'
import * as quotType from '@/store/modules/quot/mutations-type'
import { nanoid } from 'nanoid'
import { getStkStep, Direction } from '@/utils/stockPrice'
import useStock, { Market } from '@/hooks/useStock'
import useSubQuot from '@/hooks/useSubQuot'
import useDisabled from '@/hooks/useDisabled'
// 本地indexedb查询
const S5LIST = [
  {
    priceKey: 'ask1',
    qtyKey: 'askQty1',
    label: '卖1',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask2',
    qtyKey: 'askQty2',
    label: '卖2',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask3',
    qtyKey: 'askQty3',
    label: '卖3',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask4',
    qtyKey: 'askQty4',
    label: '卖4',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'ask5',
    qtyKey: 'askQty5',
    label: '卖5',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  }
]
const B5LIST = [
  {
    priceKey: 'bid1',
    qtyKey: 'bidQty1',
    label: '买1',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid2',
    qtyKey: 'bidQty2',
    label: '买2',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid3',
    qtyKey: 'bidQty3',
    label: '买3',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid4',
    qtyKey: 'bidQty4',
    label: '买4',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  },
  {
    priceKey: 'bid5',
    qtyKey: 'bidQty5',
    label: '买5',
    price: COMMON.EMPTY_LABEL,
    qty: COMMON.EMPTY_LABEL
  }
]
export default defineComponent({
  name: 'BulkTrading',
  components: {
    dropdown,
    dropdownInput,
    prompt,
    confirmPopup,
    userInfo,
    // unlock,
    tipcom
  },
  setup() {
    const inputNumRef = ref(null)
    const refPrice = ref(null)
    const keydown = (e: any) => {
      if (e.keyCode === 13) {
        nextTick(() => {
          (inputNumRef.value as any).focus()
        })
      }
    }
    const store: any = useStore()
    const route = useRoute()
    const marketType = ref('HK')
    const stockInfo = ref({
      code: '',
      market: ''
    })

    const immigrantMainAccout = storage.rcGetItem(
      StorageType.local,
      'immigrantMainAccout'
    )
    const clientId =
      immigrantMainAccout || storage.rcGetItem(StorageType.local, 'userInfo')

    // const router = useRouter()
    const state = reactive({
      tableData: tableData,
      associateList: [] as any,
      currentItem: { label: '限价单 (增强) ', value: 'ELO', valueOf: 'ELO1' },
      typeFlag: false,
      inputFlag: false,
      // 输入框选择的数据
      obj: {
        code: '',
        icon: '',
        label: '',
        value: ''
      },
      num: '', // 数量
      fundAccount: clientId,
      loading: false, // loading
      tableLoading: false, // tableLoading
      timer: '' as any,
      price: '' as any, // 价格
      bigBuyPrice: '', // 计算最大可买价格
      stock: '', // 输入框输入的数据
      bigBuy: '- -', // 最大可买 新
      bigSellNum: '- -', // 最大可卖 新
      bigSell: '- -', // 最大可卖
      totalBuy: '' as any, // 最大可买 港美
      dialogVisible: false, // 碎股确认
      confirmPopupFlag: false, // 买入卖出确认
      unlockDialogVisible: false, // 解锁交易
      placeOrderF: gql.placeMoOrder as any, // 购买 => 类型的接口
      placeOrderP: {} as any, // 购买 => 类型的参数
      listRight: {} as any, // 右侧资金显示
      activeName: 'HK', // 右侧tab
      tableDataList: [], // 右侧表格数据
      unlockFlag: false, // 解锁交易
      tradingData: {}, // 买入卖出勾选的数据
      down: down, // 图片
      diamond: diamond, // 图片
      close: close, // 图片
      buyLoading: false, // 卖出loading
      allocationIndex: '1', // 分配方式
      buyHand: 0, // 当前输入股票 买一手的数量 (美股一手都可以买 可以设置为 1 既不会出现碎股)
      promptTitle: '资产分配', // 碎股显示标题
      buyTitle: '买入确认', // 批量买入确认显示标题
      quotDetail: {
        price: ''
      } as any, // 行情详细
      s5List: S5LIST, // 卖出5挡
      b5List: B5LIST, // 买入5挡
      list: listHK, // 选项选中类型
      homeData: [] as any, // 首页传入的值
      marketFlag: true, // 市价单隐藏
      changeFlagNum: 5, // 涨跌幅 显示的颜色 icon
      inputLenovoList: [] as any, // 输入框输入股票名称或者代码 表格自动联想
      HKList: [] as any, // 港币用户列表
      USList: [] as any, // 美元用户列表
      preClose: '', // 昨收价
      preMarketFlag: true as any, // 盘前盘后
      mktCode: {} as { code: string, mkt: string },
      totalMarketValue: '', // 个股总市值
      preOnPrice: '', // 盘前盘后对比价
      currentDisabled: false, // 类型选项禁用
      sellDirection: '', // 类型选项禁用
      orderTxnReference: '', // 订单号 改单的
      extendedTradingF: '', // 改单的盘前盘后
      entrustPrice: '' as any, // 改单价
      isZht: true as any, // 中华通
      ePending: '',
      changeItemIndex: 0,
      oldSubscribeArr: [], // 旧的订阅数组
      nowSubscribeArr: [] // 当前正在订阅的数据
    })
    const readList: any = route.params.bidAskList || '[]'
    // eslint-disable-next-line
    const homeDataList = JSON.parse(readList) || [];
    state.homeData = new Map()
    homeDataList.forEach((v: any) => {
      state.homeData.set(v.fundAccount, v)
    })
    const subStatus = ref(true)
    const assetInfoParams = ref({})
    const assetInfoOptions = ref({
      enabled: false,
      notifyOnNetworkStatusChange: true
    })
    const quotHkParams = ref({})
    const quotUsParams = ref({})
    const quotAParams = ref({})
    const {
      queryAccData,
      accList,
      refetch,
      loading,
      pullData,
      isDelayQuot,
      mqttAssetIds
    } = useStock(marketType)
    const { subscribe, unSubscribeForSearch } = useSubQuot(
      isDelayQuot,
      mqttAssetIds,
      stockInfo
    )
    const {
      result: stockList,
      loading: inputLoading,
      refetch: queryStockRefetch,
      onResult: queryStockResult
    } = useQuery(gql.queryStkAssetInfo, assetInfoParams, assetInfoOptions)
    // 请求港股行情
    const quotHkOptions = ref({
      enabled: false,
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotHk, onResult: onResultHk } = useQuery(
      gql.quotHk,
      quotHkParams,
      quotHkOptions
    )
    // 请求美股行情
    const quotUsOptions = ref({
      enabled: false,
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotUs, onResult: onResultUs } = useQuery(
      gql.quotUs,
      quotUsParams,
      quotUsOptions
    )
    //  请求A股详情
    const quotAOptions = ref({
      enabled: false,
      notifyOnNetworkStatusChange: true
    })
    const { refetch: refetchQuotA, onResult: onResultA } = useQuery(
      gql.quotA,
      quotAParams,
      quotAOptions
    )
    const { isDdisableStatus } = useDisabled()

    // 切换港美股
    const typeValue = (value: any) => {
      const type = {
        [Market.HK]: Market.HK,
        [Market.US]: Market.US,
        [Market.A]: Market.A,
        [Market.SZ]: Market.A,
        [Market.SH]: Market.A,
        [Market.TW]: Market.TW
      }
      const params = {
        stockPage: {
          exchangeType: type[value],
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        },
        fundAcc: {
          exchangeType: type[value],
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        }
      }
      refetch(params)
      state.activeName = value
      marketType.value = value
    }
    // 监听推送刷新持仓列表
    const nanoidRo = computed(() => store.state.quot.orderList)
    watch(
      () => nanoidRo.value,
      () => {
        const type = {
          [Market.HK]: Market.HK,
          [Market.US]: Market.US,
          [Market.A]: Market.A,
          [Market.SZ]: Market.A,
          [Market.SH]: Market.A,
          [Market.TW]: Market.TW
        }
        const params = {
          stockPage: {
            exchangeType: type[state.activeName],
            fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
          },
          fundAcc: {
            exchangeType: type[state.activeName],
            fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
          }
        }
        refetch(params)
      },
      { deep: true }
    )
    watch(
      () => state.list,
      () => {
        state.changeItemIndex = 0
      },
      { deep: true }
    )
    // 改单
    const changOrderForm = (val: any) => {
      console.log(val)
      // eslint-disable-next-line
      clearInput();
      let hkArr = {} as any
      if (val.exchangeType === 'HK') {
        hkArr = JSON.parse(JSON.stringify(listHK)).filter(
          (v: any) => v.value === val.entrustProp
        )
      } else {
        hkArr = JSON.parse(JSON.stringify(listUS)).filter(
          (v: any) => v.value === val.entrustProp
        )
      }
      // 更改选项数组
      if (val.exchangeType === 'HK') {
        state.list = listHK
        state.currentItem = hkArr[0]
      } else {
        state.list = listUS
        state.currentItem = hkArr[0]
      }
      if (
        state.currentItem.valueOf === 'MO1' ||
        state.currentItem.valueOf === 'AMO1' ||
        state.currentItem.valueOf === 'MO2'
      ) {
        state.marketFlag = false
      } else {
        state.marketFlag = true
      }
      state.currentDisabled = true
      state.sellDirection = val.entrustBs
      state.orderTxnReference = val.orderTxnReference
      state.extendedTradingF = val.extendedTrading
      // eslint-disable-next-line
      state.preMarketFlag = val.extendedTrading === "允许" ? true : false;
      const code = `${val.stockCode}.${val.exchangeType}`
      if (val.stockCode && val.exchangeType) {
        const params = {
          id: code,
          mkt: val.exchangeType,
          code: val.stockCode
        }
        state.obj = {
          code: val.stockCode,
          icon: val.exchangeType,
          label: val.stockNameGb,
          value: ''
        }
        state.stock = code
        console.log(params)
        // eslint-disable-next-line
        queryQuot(params);
        state.entrustPrice = val.entrustPrice
        if (val.exchangeType === 'TW') {
          state.price = val.entrustPrice
        }
      }
      state.num = val.entrustAmount
      const arrL = accList.tableDataList.filter(
        (v: any) => v.stockCode === state.obj.code
      )
      if (arrL.length > 0) {
        state.bigSellNum = arrL
          .map((v: any) => v.enableAmount)
          .reduce((a: any, b: any) => a + b)
      }
    }
    // 确认改单
    const confirmChangeOrder = () => {
      if (!isDdisableStatus.isTrading) {
        ElMessage({
          message: '暂不支持',
          type: 'warning'
        })
        return
      }
      if (
        Number(state.price) === 0 &&
        state.currentItem.value !== 'MO' &&
        state.currentItem.value !== 'AMO'
      ) {
        ElMessage({
          message: '价格不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (Number(state.num) === 0) {
        ElMessage({
          message: '数量不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.tradingData = {
        fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
        entrustProp: state.currentItem.label,
        entrustPropValue: state.currentItem.value,
        entrustBs: state.sellDirection === '1' ? '买入' : '卖出',
        stockName: state.obj.label,
        stockCode: state.obj.code,
        price: state.price,
        entrustAmount: state.num,
        // eslint-disable-next-line
        amount: amountComputed.value,
        stockIcon: state.obj.icon,
        buyTitle: '改单确认'
      }
      console.log(state.tradingData)
      state.confirmPopupFlag = true
    }
    // 改单确认
    const { mutate: ModifyOrderFunc } = useMutation(gql.ModifyOrder)
    const buyNowChange = () => {
      console.log('改单确认')
      state.buyLoading = true
      let obj = {}
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        obj = {
          orderTxnReference: state.orderTxnReference, // orderTxnReference
          newEntrustAmount: Number(state.num),
          newEntrustPrice: 0
        }
      } else {
        obj = {
          orderTxnReference: state.orderTxnReference, // orderTxnReference
          newEntrustAmount: Number(state.num),
          newEntrustPrice: Number(state.price)
        }
      }
      ModifyOrderFunc({ params: obj }).then((res: any) => {
        const { modifyOrder } = res.data
        if (modifyOrder.ok) {
          ElMessage({
            message: modifyOrder.data.attendtion,
            type: 'success',
            customClass: 'custom-success'
          })
          state.buyLoading = false
          state.currentDisabled = false
          state.confirmPopupFlag = false
          // 选择盘前还是盘后
          state.preMarketFlag = true
          // 清除数据
          // eslint-disable-next-line
          clearInput();
          // 刷新右侧数据
          typeValue(state.activeName)
          store.commit(quotType.GET_ORDERS, nanoid())
          return
        }
        state.buyLoading = false
        ElMessage({
          message: modifyOrder.reason.desc,
          type: 'warning',
          customClass: 'custom-warning'
        })
        if (modifyOrder.reason.err === 20021) {
          const currentClient = storage.rcGetItem(
            StorageType.local,
            'currentClient'
          )
          const immigrantMainAccout = storage.rcGetItem(
            StorageType.local,
            'immigrantMainAccout'
          )
          // 目前只有投资移民主子账号公用主交易密码
          if (
            immigrantMainAccout &&
            [9, 10].includes(currentClient.clientType) &&
            currentClient.fundAccountType === 3
          ) {
            storage.rcRemoveItem(
              StorageType.session,
              `unlock-immigrant-${immigrantMainAccout}`
            )
          } else {
            storage.rcRemoveItem(
              StorageType.session,
              `unlock-${currentClient.clientId}`
            )
          }
          state.unlockFlag = false
          state.confirmPopupFlag = false
        }
      })
    }
    // 重新下单
    const againOrderForm = (val: any) => {
      console.log('重新下单againOrderForm', val)
      // eslint-disable-next-line
      clearInput()
      if (val) {
        const {
          stockCode,
          exchangeType,
          stockNameGb,
          entrustAmount,
          entrustProp,
          extendedTrading
        } = val
        if (entrustAmount || entrustAmount === 0) {
          state.num = entrustAmount
          console.log('state.num', state.num)
        }
        if (extendedTrading === '允许') {
          state.preMarketFlag = true
        } else {
          state.preMarketFlag = false
        }
        // 改变选择( 限价单(增强) )类型的数据
        state.activeName = exchangeType
        marketType.value = exchangeType
        if (exchangeType === Market.HK) {
          state.list = listHK
        } else if (exchangeType === Market.US) {
          state.list = listUS
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        } else if (exchangeType === Market.TW) {
          state.list = listTW
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        }
        if (entrustProp) {
          state.currentItem = JSON.parse(JSON.stringify(state.list)).filter(
            (v: any) => v.value === entrustProp
          )[0]
        }
        // 市价单隐藏价格
        if (
          state.currentItem.value === 'MO' ||
          state.currentItem.value === 'AMO'
        ) {
          state.marketFlag = false
          state.price = ''
        } else {
          state.marketFlag = true
          // eslint-disable-next-line
          state.price = state.quotDetail.price;
        }
        const code = `${stockCode}.${exchangeType}`
        if (stockCode && exchangeType) {
          const params = {
            id: code,
            mkt: exchangeType,
            code: stockCode
          }
          state.obj = {
            code: stockCode,
            icon: exchangeType,
            label: stockNameGb,
            value: ''
          }
          state.stock = code
          // eslint-disable-next-line
          queryQuot(params);
        }
        if (exchangeType === Market.TW) {
          state.num = entrustAmount
          state.buyHand = 1000
          state.price = val.entrustPrice
        }
      }
    }
    // 撤单成功
    const cancelOrderSuccess = (val: any) => {
      // eslint-disable-next-line
      if (val === "1") {
        // eslint-disable-next-line
        clearInput();
        // 刷新右侧数据
        typeValue(state.activeName)
      } else if (val === '0') {
        const currentClient = storage.rcGetItem(
          StorageType.local,
          'currentClient'
        )
        const immigrantMainAccout = storage.rcGetItem(
          StorageType.local,
          'immigrantMainAccout'
        )
        // 目前只有投资移民主子账号公用主交易密码
        if (
          immigrantMainAccout &&
          [9, 10].includes(currentClient.clientType) &&
          currentClient.fundAccountType === 3
        ) {
          storage.rcRemoveItem(
            StorageType.session,
            `unlock-immigrant-${immigrantMainAccout}`
          )
        } else {
          storage.rcRemoveItem(
            StorageType.session,
            `unlock-${currentClient.clientId}`
          )
        }
        state.unlockFlag = false
        state.confirmPopupFlag = false
      } else if (val === '-1') {
        state.unlockDialogVisible = true
      }
    }

    // 计算最大可买
    watch(
      () => accList.listRight,
      (newValue: any) => {
        if (
          [Market.A, Market.SZ, Market.SH].includes(Market[state.mktCode.mkt])
        ) {
          state.totalBuy = newValue?.enableBalance
        } else {
          state.totalBuy = newValue?.multiCurrencyBuyPower
        }
      },
      { deep: true, immediate: true }
    )
    // 计算最大可买
    watch(
      () => state.totalBuy,
      (newValue: any) => {
        if (!newValue) {
          return 0
        }
        // 锁 => 同一类型才更新
        if (state.obj.icon !== state.activeName) {
          return
        }
        const bayHandSome =
          state.totalBuy / Number(state.bigBuyPrice) -
          ((state.totalBuy / Number(state.bigBuyPrice)) % state.buyHand)
        state.bigBuy = numFormat(bayHandSome, 0)
      },
      { deep: true, immediate: true }
    )
    // 计算最大可卖
    watch(
      () => accList.tableDataList,
      (newValue: any) => {
        // 锁 => 同一类型才更新
        if (state.obj.icon !== state.activeName) {
          return
        }
        if (newValue.length > 0) {
          const arrL = accList.tableDataList.filter(
            (v: any) => v.stockCode === state.obj.code
          )
          if (arrL.length > 0) {
            state.bigSellNum = arrL
              .map((v: any) => v.enableAmount)
              .reduce((a: any, b: any) => a + b)
          }
        }
      },
      { deep: true }
    )
    // 获取右侧数据 上
    // 行情实时推送数据
    const timeIsDst = computed(() => store.getters.timeIsDst)
    const mqttQuotDetail = computed(() => store.getters.quot.detail)
    // 港股买卖五档行情推送
    const quotAskBid = computed(() => store.getters.quot.askBid)
    // 计算属性 计算涨跌的占比
    const computedRiseFall = computed(() => {
      if (state.quotDetail.changePct) {
        if (state.changeFlagNum === 2) {
          return '+' + numFormat(state.quotDetail.changePct * 100, 2) + '%'
        } else {
          return numFormat(state.quotDetail.changePct * 100, 2) + '%'
        }
      }
      return '0.00%'
    })
    // 计算属性 计算金额/最大可买
    const amountComputed = computed(() => {
      const num = new Decimal(state.price ? state.price : 0)
        .mul(new Decimal(state.num ? state.num : 0))
        .toNumber()
      return num ? numFormat(num, 2) : '' || '- -'
    })
    // 计算属性 计算涨跌的颜色
    const computedChangeColor = computed(() => {
      if (state.changeFlagNum === 0) {
        return 'num1-down'
      } else if (state.changeFlagNum === 1) {
        return 'fn-num1'
      } else if (state.changeFlagNum === 2) {
        return 'num1-up'
      } else if (state.changeFlagNum === 5) {
        return 'num1-nor'
      }
    })
    // 监听数量变化
    watch(
      () => state.num,
      (newValue: any) => {
        newValue = (newValue + '').replace(/[^0-9]/g, '')
        newValue = (newValue + '').replace(/0*([1-9]\d*|0\.\d+)/, '$1')
        if (newValue > 0) {
          state.num = newValue
        } else {
          state.num = ''
        }
      }
    )
    // 监听价格变化
    watch(
      () => state.price,
      (newValue: any) => {
        newValue = (newValue + '')
          .replace(/0*([1-9]\d*|0\.\d+)/, '$1')
          .replace(/\D*(\d*)(\.?)(\d{0,4})\d*/, '$1$2$3')
        if (newValue >= 0) {
          state.price = newValue
          if (
            state.currentItem.value === 'MO' ||
            state.currentItem.value === 'AMO'
          ) {
            state.bigBuyPrice = state.price || state.quotDetail.price
          } else {
            state.bigBuyPrice = state.price
          }
        } else {
          state.price = ''
          if (
            state.currentItem.value === 'MO' ||
            state.currentItem.value === 'AMO'
          ) {
            state.bigBuyPrice = state.price || state.quotDetail.price
          } else {
            state.bigBuyPrice = state.price
          }
        }
      }
    )
    // 计算最大可买 bigBuyPrice
    watch(
      () => state.bigBuyPrice,
      (newValue: any) => {
        if (!newValue) {
          state.bigBuy = '- -'
          return
        }
        // value 值转换
        const cIcon =
          state.obj.icon === 'SH' || state.obj.icon === 'SZ'
            ? 'A'
            : state.obj.icon
        const aCtiveValue =
          state.activeName === 'SH' || state.activeName === 'SZ'
            ? 'A'
            : state.activeName
        if (cIcon !== aCtiveValue) {
          return
        }
        if (state.totalBuy > 0) {
          const bayHandSome =
            state.totalBuy / Number(newValue) -
            ((state.totalBuy / Number(newValue)) % state.buyHand)
          state.bigBuy = numFormat(bayHandSome, 0)
        }
      }
    )
    // 监听输入获取的数据
    watch(
      () => state.quotDetail,
      (newValue: any) => {
        if (state.obj.label === '') {
          return
        }
        console.log(state.quotDetail, '输入查询的数据')
        // state.preClose = state.quotDetail.preClose
        // 中华通赋值
        if (newValue.isZht !== undefined) {
          state.isZht = newValue.isZht
        }
        if (
          state.currentItem.value === 'MO' ||
          state.currentItem.value === 'AMO'
        ) {
          state.bigBuyPrice = newValue.price
        }
        state.buyHand = Number(newValue.lotSize || 1000)
        if (newValue.change?.includes('-')) {
          // 跌 0
          state.changeFlagNum = 0
        } else if (Number(newValue.change) === 0) {
          // 不涨不跌 1
          state.changeFlagNum = 1
        } else if (newValue.change) {
          // 涨 2
          state.changeFlagNum = 2
        } else {
          state.changeFlagNum = 1
        }
      },
      { deep: true }
    )
    // 买卖档位价格颜色
    const autoColor = (price: any) => {
      if (price === '--') {
        return
      }
      if (Number(state.preOnPrice) > Number(price)) {
        return 3
      } else if (Number(state.preOnPrice) === Number(price)) {
        return 2
      } else if (Number(state.preOnPrice) < Number(price)) {
        return 1
      }
    }
    const handlerClick = (val: String) => {
      switch (val) {
        case 'subPrice':
          if (!state.price) {
            return
          }
          const price1 = new Decimal(state.price).sub(
            new Decimal(
              getStkStep(
                state.price,
                state.obj.icon,
                state.quotDetail.schType,
                Direction.Sub
              )
            )
          ) as any
          state.price =
            state.obj.icon === 'SH' || state.obj.icon === 'SZ'
              ? numFormat(price1, 2).replace(/,/g, '')
              : numFormat(price1, 3).replace(/,/g, '')
          break
        case 'addPrice':
          if (!state.price) {
            return
          }
          const price = new Decimal(state.price).plus(
            new Decimal(
              getStkStep(
                state.price,
                state.obj.icon,
                state.quotDetail.schType,
                Direction.Add
              )
            )
          ) as any
          console.log('price', Number(price))
          state.price =
            state.obj.icon === 'SH' || state.obj.icon === 'SZ'
              ? numFormat(price, 2).replace(/,/g, '')
              : numFormat(Number(price), 3).replace(/,/g, '')
          break
        case 'addNum':
          state.num = new Decimal(Number(state.num || 0)).plus(
            new Decimal(state.buyHand)
          ) as any
          break
        case 'subNum':
          if (!state.num || Number(state.num) - state.buyHand < 0) {
            return
          }
          state.num = new Decimal(Number(state.num || 0)).sub(
            new Decimal(state.buyHand)
          ) as any
          break
        default:
          break
      }
    }
    // 选择了选项(下拉)
    const changeItem = (
      value: any,
      label: string,
      valueOf: string,
      index: any
    ) => {
      // 选中了市价单 竞价市价单 隐藏价格 金额显示 - - 最大可买 - -
      if (value === 'MO' || value === 'AMO') {
        state.marketFlag = false
        state.price = ''
      } else {
        state.marketFlag = true
        // eslint-disable-next-line
        state.price = state.quotDetail.price;
      }
      state.currentItem.value = value
      state.currentItem.label = label
      state.currentItem.valueOf = valueOf
      state.typeFlag = false
      state.changeItemIndex = index
    }
    // 查询对应个股行情
    const queryQuot = (data: any) => {
      const { id, mkt, code } = data
      state.mktCode = {
        mkt,
        code
      }
      stockInfo.value = {
        code,
        market: mkt
      }
      if (mkt === 'HK') {
        quotHkParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailHk
          }
        }
        if (!quotHkOptions.value.enabled) {
          quotHkOptions.value.enabled = true
        } else {
          refetchQuotHk(quotHkParams.value)
        }
      } else if (mkt === 'US') {
        quotUsParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailUs
          }
        }
        if (!quotUsOptions.value.enabled) {
          quotUsOptions.value.enabled = true
        }
        refetchQuotUs(quotUsParams.value)
      } else if (mkt === 'SZ' || mkt === 'SH') {
        quotAParams.value = {
          filter: {
            assetIds: [id],
            fields: STOCKQUOTE.detailA
          }
        }
        if (!quotAOptions.value.enabled) {
          quotAOptions.value.enabled = true
        }
        refetchQuotA(quotAParams.value)
      } else if (mkt === 'TW') {
        state.num = '1000'
        state.buyHand = 1000
      }
    }
    const init = () => {
      if (homeDataList.length) {
        const {
          stockCode,
          exchangeType,
          stockNameGb,
          entrustAmount,
          entrustProp
        } = homeDataList[0]
        if (entrustAmount) {
          state.num = entrustAmount
        }
        // 改变选择( 限价单(增强) )类型的数据
        state.activeName = exchangeType
        marketType.value = exchangeType
        if (exchangeType === 'HK') {
          state.list = listHK
        } else if (exchangeType === 'US') {
          state.list = listUS
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        } else if (exchangeType === 'TW') {
          state.list = listTW
          state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        }
        if (entrustProp) {
          state.currentItem = JSON.parse(JSON.stringify(state.list)).filter(
            (v: any) => v.value === entrustProp
          )[0]
        }
        // 市价单隐藏价格
        if (
          state.currentItem.value === 'MO' ||
          state.currentItem.value === 'AMO'
        ) {
          state.marketFlag = false
          state.price = ''
        } else {
          state.marketFlag = true
          // eslint-disable-next-line
          state.price = state.quotDetail.price;
        }
        const code = `${stockCode}.${exchangeType}`
        if (stockCode && exchangeType) {
          const params = {
            id: code,
            mkt: exchangeType,
            code: stockCode
          }
          state.obj = {
            code: stockCode,
            icon: exchangeType,
            label: stockNameGb,
            value: ''
          }
          state.stock = code
          queryQuot(params)
        }
      }
    }
    // 港股个股行情
    const getQuotCallback = (list: any) => {
      if (state.obj.label === '' || !state.obj.label) {
        return
      }
      subStatus.value = true
      let data
      if (state.mktCode.mkt === 'HK') {
        data = transformQuoteField(list, STOCKQUOTE.detailHk) || []
        state.quotDetail = data.length ? data[0] : {}
      } else if (state.mktCode.mkt === 'US') {
        // 需要盘前盘后数据
        data = transformQuoteField(list, STOCKQUOTE.detailUs) || []
        const res = data.length ? data[0] : {}
        switch (res.afBfStatus) {
          case '0':
            // 取162行情数据
            let beforeData = {
              high: '--',
              low: '--',
              price: '',
              change: '',
              changePct: '',
              bid1: '',
              bidQty1: '',
              ask1: '',
              askQty1: ''
            }
            if (res.fieldUsAfter !== '') {
              beforeData = transformSocketFieldUsAfterOrBefore(
                res.fieldUsBefore
              )
            }
            state.quotDetail = {
              ...res,
              ...beforeData
            }
            break
          case '1':
            // 取163行情数据
            let afterData = {
              high: '--',
              low: '--',
              price: '',
              change: '',
              changePct: '',
              bid1: '',
              bidQty1: '',
              ask1: '',
              askQty1: ''
            }
            if (res.fieldUsAfter !== '') {
              afterData = transformSocketFieldUsAfterOrBefore(res.fieldUsAfter)
            }
            state.quotDetail = {
              ...res,
              ...afterData
            }
            break
          default:
            state.quotDetail = data.length ? data[0] : {}
            state.preOnPrice = state.quotDetail.preClose
            break
        }
      } else if (state.mktCode.mkt === 'SH' || state.mktCode.mkt === 'SZ') {
        data = transformQuoteField(list, STOCKQUOTE.detailA) || []
        state.quotDetail = data.length ? data[0] : {}
      }
      console.log('data.lengthdata.length', data)
      let b5List = state.b5List
      let s5List = state.s5List
      if (state.mktCode.mkt === 'HK') {
        subscribe()
      } else if (state.mktCode.mkt === 'US') {
        subscribe()
        b5List = state.b5List.filter((val) => val.qtyKey === 'bidQty1')
        s5List = state.s5List.filter((val) => val.qtyKey === 'askQty1')
      } else if (state.mktCode.mkt === 'SH' || state.mktCode.mkt === 'SZ') {
        subscribe()
        b5List = state.b5List.filter((val) => val.qtyKey === 'bidQty1')
        s5List = state.s5List.filter((val) => val.qtyKey === 'askQty1')
      }
      // 买卖档位数据
      state.b5List = b5List.map((val) => {
        return {
          ...val,
          price: state.quotDetail[val.priceKey] || COMMON.EMPTY_LABEL,
          qty: state.quotDetail[val.qtyKey] || COMMON.EMPTY_LABEL
        }
      })
      state.s5List = s5List.map((val) => {
        return {
          ...val,
          price: state.quotDetail[val.priceKey] || COMMON.EMPTY_LABEL,
          qty: state.quotDetail[val.qtyKey] || COMMON.EMPTY_LABEL
        }
      })
      // ---------------- 输入股票代码输入框显示价格 ------------------------
      if (state.marketFlag) {
        if (!state.entrustPrice) {
          state.price = state.quotDetail.price
        } else {
          state.price = numFormat(state.entrustPrice, 3).replace(/,/g, '')
        }
      }
    }
    onResultHk((result) => {
      const { data, loading } = result
      console.log('data', data)
      if (!loading) {
        const res = data.quotHk.result?.data || []
        state.preOnPrice = res.length && res[0][6]
        getQuotCallback(res)
      }
    })
    onResultUs((result) => {
      const { data, loading } = result
      console.log('data =>>>>>>>>>>>>>>>>>>>>>', data)
      if (!loading) {
        const res = data.quotUs.result?.data || []
        state.preOnPrice = res.length && res[0][2]
        getQuotCallback(res)
      }
    })
    // 获取A股行情
    onResultA((result) => {
      const { data, loading } = result
      console.log('data =>>>>>>>>>>>>>>>>>>>>>', data)
      if (!loading) {
        const res = data.quotA.result?.data || []
        state.preOnPrice = res.length && res[0][6]
        getQuotCallback(res)
      }
    })
    watch([mqttQuotDetail, subStatus], ([quotVal, statusVal]) => {
      if (statusVal && quotVal && quotVal.length) {
        const result = quotVal[0]
        if (
          state.obj.label &&
          result.assetId.split('.')[0] === state.obj.code
        ) {
          state.quotDetail = {
            ...state.quotDetail,
            price: result.price,
            change: result.change,
            changePct: result.changePct
          }
        }
        // 推送用户持仓现价
        // pullData(quotVal, quotVal[0].assetId.split('.')[0], quotVal[0].price)
      }
    })
    watch([quotAskBid, subStatus], ([quotVal, statusVal]) => {
      if (!state.obj.code) return
      // console.log('quotVal', quotVal)
      if (statusVal && quotVal && quotVal.length) {
        const list = quotVal[0]
        // 买卖档位数据
        if (
          state.obj.label &&
          quotVal[0].assetId.split('.')[0] === state.obj.code
        ) {
          state.b5List = state.b5List.map((val) => {
            return {
              ...val,
              price: list[val.priceKey],
              qty: list[val.qtyKey]
            }
          })
          state.s5List = state.s5List.map((val) => {
            return {
              ...val,
              price: list[val.priceKey],
              qty: list[val.qtyKey]
            }
          })
        }
      }
    })
    // 输入框输入(点击快速下单) 类型快速转换逻辑
    const typeChange = (type: string) => {
      const arrHK = JSON.parse(JSON.stringify(listHK)).map(
        (v: any) => v.valueOf
      )
      const arrUS = JSON.parse(JSON.stringify(listUS)).map(
        (v: any) => v.valueOf
      )
      const arrA = JSON.parse(JSON.stringify(listA)).map((v: any) => v.valueOf)
      const arrTW = JSON.parse(JSON.stringify(listTW)).map(
        (v: any) => v.valueOf
      )

      const currentItemFlag = state.list.findIndex(
        (v: any) => !arrHK.includes(v.valueOf)
      )
      const currentItemUSFlag = state.list.findIndex(
        (v: any) => !arrUS.includes(v.valueOf)
      )
      const currentItemAFlag = state.list.findIndex(
        (v: any) => !arrA.includes(v.valueOf)
      )
      const currentItemTWFlag = state.list.findIndex(
        (v: any) => !arrTW.includes(v.valueOf)
      )
      const catchCurrentItem = JSON.parse(JSON.stringify(state.currentItem))
      // 更改选项数组
      if (type === 'HK' && currentItemFlag === -1) {
        state.list = listHK
        state.currentItem = catchCurrentItem
      } else if (type === 'HK' && currentItemFlag !== -1) {
        state.list = listHK
        state.currentItem = {
          label: '限价单 (增强) ',
          value: 'ELO',
          valueOf: 'ELO1'
        }
        state.marketFlag = true
      } else if (type === 'US' && currentItemUSFlag === -1) {
        state.list = listUS
        state.currentItem = catchCurrentItem
      } else if (type === 'US' && currentItemUSFlag !== -1) {
        state.list = listUS
        state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        state.marketFlag = true
      } else if ((type === 'SZ' || type === 'SH') && currentItemAFlag === -1) {
        state.list = listA
        state.currentItem = catchCurrentItem
      } else if ((type === 'SZ' || type === 'SH') && currentItemAFlag !== -1) {
        state.list = listA
        state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO3' }
        state.marketFlag = true
      } else if (type === 'TW' && currentItemTWFlag === -1) {
        state.list = listTW
        state.currentItem = catchCurrentItem
      } else if (type === 'TW' && currentItemTWFlag !== -1) {
        state.list = listUS
        state.currentItem = { label: '限价单', value: 'LO', valueOf: 'LO2' }
        state.marketFlag = true
      }
    }
    // 选择了选项(输入框)
    const selectItem = (obj: any) => {
      console.log('selectItem obj', obj)
      typeChange(obj.icon)
      queryQuot(obj)
      state.obj = obj
      state.activeName = state.obj.icon
      marketType.value = state.obj.icon
      // typeValue(state.activeName)
      state.loading = true
      state.tableLoading = true
      console.log('accList.listRight', accList.listRight)
      if (
        [Market.A, Market.SZ, Market.SH].includes(Market[state.mktCode.mkt])
      ) {
        state.totalBuy = accList.listRight?.enableBalance
      } else {
        state.totalBuy = accList.listRight?.multiCurrencyBuyPower
      }
      console.log('state.totalBuy', state.totalBuy)
      const arrL = accList.tableDataList.filter(
        (v: any) => v.stockCode === state.obj.code
      )
      if (arrL.length > 0) {
        state.bigSellNum = arrL
          .map((v: any) => v.enableAmount)
          .reduce((a: any, b: any) => a + b)
      }

      const type = {
        [Market.HK]: Market.HK,
        [Market.US]: Market.US,
        [Market.A]: Market.A,
        [Market.SZ]: Market.A,
        [Market.SH]: Market.A,
        [Market.TW]: Market.TW
      }
      const params = {
        stockPage: {
          exchangeType: type[state.activeName],
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        },
        fundAcc: {
          exchangeType: type[state.activeName],
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        }
      }
      refetch(params)
      state.inputFlag = false
      // 聚焦数量输入框
      if (state.marketFlag) {
        nextTick(() => {
          (refPrice.value as any).focus()
        })
      } else {
        nextTick(() => {
          (inputNumRef.value as any).focus()
        })
      }
    }
    // 查询股票返回值
    queryStockResult((result) => {
      const { data, loading } = result
      if (!loading) {
        let array = [...data?.stkAssetInfo.result.data]
        if (process.env.VUE_APP_H5_A_SWITCH === 'off') {
          array = array.filter((v: any) => v.mkt === 'US' || v.mkt === 'HK')
        }
        console.log(
          '%c [  ]-2248',
          'font-size:13px; background:pink; color:#bf2c9f;',
          array
        )
        state.associateList = array.map((res: any) => ({
          id: res.id,
          label: res.sn,
          value: 1,
          icon: res.mkt,
          code: res.c,
          ...res
        }))
      }
    })
    // 输入框输入的事件 (接入防抖)
    const inputStock = _.debounce(() => {
      if (state.stock === '') {
        state.associateList = []
        return
      }
      state.associateList = []
      assetInfoParams.value = {
        filter: {
          searchContent: state.stock,
          stkTypeList: ['7', '4']
        }
      }
      if (assetInfoOptions.value.enabled) {
        queryStockRefetch(assetInfoParams.value)
      } else {
        assetInfoOptions.value.enabled = true
      }
      state.inputFlag = true
    }, 500)
    // 清除输入框输入的数据
    const clearInput = (onUnmounted?: string) => {
      state.preMarketFlag = true // 取消改单盘前盘后状态初始化 改为 允许
      state.preClose = ''
      state.preOnPrice = ''
      state.entrustPrice = '' // 清空改单价
      state.currentDisabled = false
      state.sellDirection = ''
      state.orderTxnReference = ''
      state.extendedTradingF = ''
      state.stock = ''
      state.obj = {} as any
      state.inputFlag = false
      state.s5List = S5LIST
      state.b5List = B5LIST
      state.quotDetail = {} as any
      state.price = ''
      state.num = ''
      stockInfo.value = {
        code: '',
        market: ''
      }
      store.commit(quotType.QUOT_DETAIL, { detail: [] })
      store.commit(quotType.ASKBID5, { askBid: [] })
      unSubscribeForSearch()
      state.bigSellNum = '- -'
      state.bigBuy = '- -'
    }
    // 购买验证
    const purchaseValidation = (title: string) => {
      let entrustBs = 'bid'
      if (title === '买入确认') {
        entrustBs = 'bid'
      } else {
        entrustBs = 'ask'
      }
      const paramsBody = {
        fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
        exchangeType: state.obj.icon, // 港股 HK 美股 US
        stockCode: state.obj.code, // 股票代码
        entrustBs: entrustBs, // bid 买入 ask 卖出
        entrustAmount: Number(state.num),
        // entrustPrice: Number(state.price), // 价格
        checkFlag: '' // 空
      }
      // 购买的单 是什么类型的单
      const placeOrder = [
        {
          value: 'ELO',
          request: gql.placeEloOrder,
          params: { ...paramsBody, entrustPrice: Number(state.price) }
        },
        {
          value: 'MO',
          request: gql.placeMoOrder,
          params:
            state.obj.icon === 'HK'
              ? { ...paramsBody }
              : { ...paramsBody, extendedTrading: state.preMarketFlag }
        },
        {
          value: 'ALO',
          request: gql.placeAloOrder,
          params: { ...paramsBody, entrustPrice: Number(state.price) }
        },
        {
          value: 'AMO',
          request: gql.placeAmoOrder,
          params: { ...paramsBody }
        },
        {
          value: 'LO',
          request: gql.placeLoOrder,
          params:
            state.obj.icon === 'US'
              ? {
                  ...paramsBody,
                  entrustPrice: Number(state.price),
                  extendedTrading: state.preMarketFlag
                }
              : {
                  ...paramsBody,
                  entrustPrice: Number(state.price)
                }
        }
      ]
      const arrObj = placeOrder.filter(
        (v: any) => v.value === state.currentItem.value
      )[0]
      state.placeOrderF = arrObj.request
      console.log(
        state.placeOrderF,
        'state.placeOrderFstate.placeOrderFstate.placeOrderF'
      )
      state.placeOrderP = arrObj.params
      if (state.stock === '') {
        ElMessage({
          message: '请输入股票代码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (
        Number(state.price) === 0 &&
        state.currentItem.value !== 'MO' &&
        state.currentItem.value !== 'AMO'
      ) {
        ElMessage({
          message: '价格不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (Number(state.num) === 0) {
        ElMessage({
          message: '数量不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.buyTitle = title
      state.tradingData = {
        fundAccount: storage.rcGetItem(StorageType.local, 'userInfo'),
        entrustProp: state.currentItem.label,
        entrustPropValue: state.currentItem.value,
        entrustBs: state.buyTitle === '买入确认' ? '买入' : '卖出',
        stockName: state.obj.label,
        stockCode: state.obj.code,
        price: state.price,
        entrustAmount: state.num,
        amount: amountComputed.value,
        stockIcon: state.obj.icon,
        buyTitle: title
      }
      // 判断是否为中华通
      if (state.isZht === '0' && title === '买入确认') {
        ElMessageBox.confirm(
          '标有该标识的股票，不属于A股通标的，暂不支持交易。为保障您的权益，您仍可尝试下单，避免在标识状态错误时造成损失。',
          '交易提醒',
          {
            confirmButtonText: '仍要下单',
            cancelButtonText: '知道了',
            customClass: 'message-info-change',
            showClose: false,
            center: true,
            type: 'warning'
          }
        )
          .then(() => {
            console.log(state.tradingData)
            state.confirmPopupFlag = true
          })
          .catch(() => ({}))
      } else {
        state.confirmPopupFlag = true
      }
    }
    // 解锁交易
    const unlockBuy = () => {
      state.unlockDialogVisible = true
    }
    const unlockFlagClick = (value: any) => {
      state.unlockFlag = value
    }
    // 买入
    const bulkBuy = () => {
      if (!isDdisableStatus.isTrading) {
        ElMessage({
          message: '暂不支持',
          type: 'warning'
        })
        return
      }
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        state.price = ''
      } else {
        // eslint-disable-next-line
        // state.price = state.quotDetail.price / * 算最大可买 / 影响购买价格 * /
      }
      purchaseValidation('买入确认')
    }
    // 卖出
    const batchSell = () => {
      if (!isDdisableStatus.isTrading) {
        ElMessage({
          message: '暂不支持',
          type: 'warning'
        })
        return
      }
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        state.price = ''
      } else {
        // eslint-disable-next-line
        // state.price = state.quotDetail.price / * 算最大可买 / 影响购买价格 * /
      }
      purchaseValidation('卖出确认')
    }
    // (碎股取整)确定
    const brokenStock = () => {
      console.log('碎股取整')
      // 处理碎股将碎股全部取整 (-  -) => 取整之后就变成了自定义分配了
      state.allocationIndex = '2'
      state.tableData.forEach((v: any) => {
        v.num =
          v.num % state.buyHand === 0 ? v.num : v.num - (v.num % state.buyHand)
      })
      state.dialogVisible = false
    }
    // 买入卖出的方法
    const { mutate: placeOrderFuncElo } = useMutation(gql.placeEloOrder)
    const { mutate: placeOrderFuncMo } = useMutation(gql.placeMoOrder)
    const { mutate: placeOrderFuncAlo } = useMutation(gql.placeAloOrder)
    const { mutate: placeOrderFuncAmo } = useMutation(gql.placeAmoOrder)
    const { mutate: placeOrderFuncLo } = useMutation(gql.placeLoOrder)
    // 订单成功失败的方法
    const requestFun = (result: any) => {
      if (result.ok) {
        ElMessage({
          message: '订单已提交',
          type: 'success',
          customClass: 'custom-success'
        })
        state.buyLoading = false
        state.confirmPopupFlag = false
        // 清除数据
        clearInput()
        // 刷新右侧数据
        typeValue(
          state.activeName === 'SZ' || state.activeName === 'SH'
            ? 'A'
            : state.activeName
        )
        store.commit(quotType.GET_ORDERS, nanoid())
        state.preMarketFlag = true
        return
      }
      state.buyLoading = false
      ElMessage({
        message: result.reason.desc || '订单提交失败',
        type: 'error',
        customClass: 'custom-error'
      })
      if (result.reason.err === 20021 || result.reason.err === 20000) {
        const currentClient = storage.rcGetItem(
          StorageType.local,
          'currentClient'
        )
        const immigrantMainAccout = storage.rcGetItem(
          StorageType.local,
          'immigrantMainAccout'
        )
        // 目前只有投资移民主子账号公用主交易密码
        if (currentClient?.fundAccountType === 30) {
          storage.rcRemoveItem(
            StorageType.session,
            `unlock-immigrant-${immigrantMainAccout}`
          )
        } else {
          storage.rcRemoveItem(StorageType.session, `unlock-${clientId}`)
        }
        state.unlockFlag = false
        state.confirmPopupFlag = false
      }
    }
    // 买入(卖出)确认
    const buyNow = () => {
      state.buyLoading = true
      console.log(state.placeOrderP, '买入卖出的参数')
      switch (state.currentItem.value) {
        case 'ELO':
          placeOrderFuncElo({ params: state.placeOrderP }).then(
            (result: any) => {
              const { placeEloOrder } = result.data
              requestFun(placeEloOrder)
            }
          )
          break
        case 'MO':
          placeOrderFuncMo({ params: state.placeOrderP }).then(
            (result: any) => {
              const { placeMoOrder } = result.data
              requestFun(placeMoOrder)
            }
          )
          break
        case 'ALO':
          placeOrderFuncAlo({ params: state.placeOrderP }).then(
            (result: any) => {
              const { placeAloOrder } = result.data
              requestFun(placeAloOrder)
            }
          )
          break
        case 'AMO':
          placeOrderFuncAmo({ params: state.placeOrderP }).then(
            (result: any) => {
              const { placeAmoOrder } = result.data
              requestFun(placeAmoOrder)
            }
          )
          break
        case 'LO':
          placeOrderFuncLo({ params: state.placeOrderP }).then(
            (result: any) => {
              const { placeLoOrder } = result.data
              requestFun(placeLoOrder)
            }
          )
          break
        default:
          break
      }
    }
    // 盘前盘后
    const market = (status: boolean) => {
      console.log('timeIsDst', timeIsDst.value)
      //  不允许
      if (!status) {
        // 夏令时
        if (timeIsDst.value) {
          const timer = new Date()
          const start = timer.setHours(4, 0, 0, 0)
          const end = timer.setHours(8, 0, 0, 0)
          const nowTime = new Date().getTime()
          if (nowTime >= start && nowTime <= end) {
            ElMessage.warning('04:00-08:00时段暂不支持选择')
            return
          }
        } else {
          // 冬令时
          const timer = new Date()
          const start = timer.setHours(5, 0, 0, 0)
          const end = timer.setHours(9, 0, 0, 0)
          const nowTime = new Date().getTime()
          if (nowTime >= start && nowTime <= end) {
            ElMessage.warning('05:00-09:00时段暂不支持选择')
            return
          }
        }
      }
      state.preMarketFlag = status
      // state.preMarketFlag = !state.preMarketFlag
    }
    // 选择表格内股票快速购买
    const checkRowClick = (val: any) => {
      clearInput()
      typeChange(val.exchangeType)
      const code = `${val.stockCode}.${val.exchangeType}`
      if (val.stockCode && val.exchangeType) {
        const params = {
          id: code,
          mkt: val.exchangeType,
          code: val.stockCode
        }
        state.obj = {
          code: val.stockCode,
          icon: val.exchangeType,
          label: val.stockNameGb,
          value: ''
        }
        state.stock = code
        console.log(params)
        queryQuot(params)
      }
      const arrL = accList.tableDataList.filter(
        (v: any) => v.stockCode === state.obj.code
      )
      if (arrL.length > 0) {
        state.bigSellNum = arrL
          .map((v: any) => v.enableAmount)
          .reduce((a: any, b: any) => a + b)
      }
    }
    // 选择买手-> 卖手
    const selectSeller = (item: any) => {
      // 市价单 竞价市价单 选中无效
      if (
        state.currentItem.value === 'MO' ||
        state.currentItem.value === 'AMO'
      ) {
        return
      }
      state.price = item.price
    }
    const hidePanel = () => {
      state.typeFlag = false
    }
    const show = () => {
      state.typeFlag = true
      document.addEventListener('click', hidePanel, false)
    }
    const hide = () => {
      state.typeFlag = false
      document.addEventListener('click', hidePanel, false)
    }
    // 选择类型
    const changeType = (event: any) => {
      // A股2022-2-11目前只有限价单
      if (state.currentItem.valueOf === 'LO3') {
        return
      }
      event || (event = window.event)
      event.stopPropagation = event.stopPropagation
        ? event.stopPropagation()
        : event.cancelBubble
      state.typeFlag ? hide() : show()
    }
    const isShowAfterQuot = computed(
      () =>
        state.mktCode.mkt === Market.US &&
        (state.currentItem.valueOf === 'LO2' ||
          state.currentItem.valueOf === 'MO2')
    )

    // 正常显示页面
    const isShowAllow = computed(
      () =>
        (state.currentItem.valueOf === 'LO2' || state.currentItem.valueOf) &&
        !state.currentDisabled &&
        state.mktCode.mkt === Market.US
    )
    // 改单页面
    const isShowAllowOrder = computed(
      () =>
        (state.currentItem.valueOf === 'LO2' || state.currentItem.valueOf) &&
        state.currentDisabled &&
        state.mktCode.mkt === Market.US
    )

    const isShowJK = computed(
      () =>
        (state.currentItem.valueOf === 'LO2' ||
          state.currentItem.valueOf === 'MO2') &&
        state.obj.label &&
        state.mktCode.mkt === Market.US
    )

    const setIntervalFunc = () => {
      const currentClient = storage.rcGetItem(
        StorageType.local,
        'currentClient'
      )
      const immigrantMainAccout = storage.rcGetItem(
        StorageType.local,
        'immigrantMainAccout'
      )
      // 目前只有投资移民主子账号公用主交易密码
      if (
        immigrantMainAccout &&
        [9, 10].includes(currentClient.clientType) &&
        currentClient.fundAccountType === 3
      ) {
        // console.log('immigrantMainAccout', currentClient)
        state.unlockFlag = !!storage.rcGetItem(
          StorageType.session,
          `unlock-immigrant-${immigrantMainAccout}`
        )
      } else {
        state.unlockFlag = !!storage.rcGetItem(
          StorageType.session,
          `unlock-${currentClient.clientId}`
        )
      }
      if (
        storage.rcGetItem(
          StorageType.session,
          `unlock-${currentClient.clientId}`
        ) &&
        storage.rcGetItem(
          StorageType.session,
          `time-${currentClient.clientId}`
        ) < new Date().getTime()
      ) {
        storage.rcRemoveItem(
          StorageType.session,
          `unlock-${currentClient.clientId}`
        )
        state.unlockFlag = false
      }
      if (
        storage.rcGetItem(
          StorageType.session,
          `unlock-immigrant-${immigrantMainAccout}`
        ) &&
        storage.rcGetItem(
          StorageType.session,
          `time-immigrant-${immigrantMainAccout}`
        ) < new Date().getTime()
      ) {
        storage.rcRemoveItem(
          StorageType.session,
          `unlock-immigrant-${immigrantMainAccout}`
        )
        state.unlockFlag = false
      }
    }

    onMounted(() => {
      init()
      const params = {
        stockPage: {
          exchangeType: marketType.value,
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        },
        fundAcc: {
          exchangeType: marketType.value,
          fundAccount: storage.rcGetItem(StorageType.local, 'userInfo')
        }
      }
      queryAccData(gql.homeAccData, params)
      setIntervalFunc()
      state.timer = setInterval(() => {
        setIntervalFunc()
      }, 1000)
    })
    onBeforeUnmount(() => {
      hide()
      clearInterval(state.timer)
    })
    onUnmounted(() => {
      clearInput('onUnmounted')
    })
    return {
      ...toRefs(state),
      tHeaderList,
      inputStock,
      changeType,
      changeItem,
      selectItem,
      clearInput,
      brokenStock,
      up,
      info,
      infop,
      info1,
      ok2,
      ok1,
      show,
      hide,
      hidePanel,
      handlerClick,
      bulkBuy,
      batchSell,
      selectSeller,
      buyNow,
      stockList,
      amountComputed,
      computedRiseFall,
      numFormatK,
      numFormat,
      unlockBuy,
      unlockFlagClick,
      typeValue,
      changOrderForm,
      inputLoading,
      autoColor,
      market,
      checkRowClick,
      Decimal,
      confirmChangeOrder,
      buyNowChange,
      againOrderForm,
      cancelOrderSuccess,
      keydown,
      inputNumRef,
      refPrice,
      computedChangeColor,
      accList,
      refetch,
      loading,
      isDdisableStatus,
      isShowAfterQuot,
      isShowAllowOrder,
      isShowAllow,
      isShowJK
    }
  }
})
